@use "sass:meta" as ---kha1f6wtc2;@import '~foundation-sites/scss/components/table';

.offer-description {
  margin-bottom: $global-margin;
  overflow-x: auto;

  p:empty {
    display: none;
  }

  p > img {
    display: table;
    margin: 0 auto;
  }

  table {
    @include table;

    //@include table-scroll;
    @include table-hover;

    tbody {
      @include -zf-table-stripe(odd);

      td {
        font-size: rem-calc(12);
        border: 1px solid $medium-gray;
      }
    }
  }

  iframe {
    margin-bottom: $global-margin;
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3780");