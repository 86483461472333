@use "sass:meta" as ---kha1f6wtc2;.market-filters {
  border: solid 1px $light-gray;
  border-radius: .5rem;
  font-size: rem-calc(15);
  padding: $global-margin;
  margin-bottom: 1.25rem;

  &.sorting {
    background-color: $alice-blue;

    form {
      display: flex;
      gap: 1rem;

      @include breakpoint(small only, large) {
        flex-direction: column;
      }

      @include breakpoint(medium only) {
        label[for="id_keyword"] {
          display: flex;
          gap: 1rem;
          align-items: center;
          line-height: normal;
        }

        label[for="id_in_stock"] {
          display: none;
        }

        label {
          font-size: rem-calc(12);
        }
      }

      @include breakpoint(medium down) {
        .price, .sort, .submit {
          display: flex;
          gap: 1rem;
          align-items: center;
        }

        .submit {
          justify-content: space-between;
        }
      }

      .price-sort {
        display: flex;
        gap: 1rem;

        @include breakpoint(small only) {
          flex-direction: column;
        }

        @include breakpoint(medium only) {
          width: 60%;

          .price {
            flex-shrink: 2;
          }

          .sort {
            flex-shrink: 1;
          }

          label[for="id_sort"] {
            display: none;
          }
        }
      }

      .price-range {
        display: flex;
        gap: 0.4rem;
        align-items: center;

        input {
          min-width: 20%;
          border: 1px solid $medium-gray;
          border-radius: $global-radius;
        }
      }

      .id_in_stock {
        @include breakpoint(medium only) {
          display: none;
        }

        & label {
          display: flex;
          align-items: center;
          gap: .6rem;
        }
      }

      input, select {
        margin: 0;
        height: 35px;
        font-size: rem-calc(14);

        @include breakpoint(medium only) {
          font-size: rem-calc(13);
        }
      }

      input[type="checkbox"] {
        /* Double-sized Checkboxes */
        transform: scale(1.2);
        padding: 10px;
      }

      input[type="submit"] {
        @include button-style(map_get($foundation-palette, san-marino), auto, $white);

        height: fit-content;
      }
    }
  }

  dl {
    &:last-child {
      margin-bottom: 0;
    }

    dt {
      font-weight: $global-weight-bold;
    }

    dd {
      margin-bottom: .25rem;

      a,
      a:visited {
        color: $primary-color;
      }

      a:hover {
        color: $anchor-color-hover;
      }

      &.selected {
        font-weight: $global-weight-bold;
      }

      a.remove {
        color: $anchor-color-hover !important;
        font-size: rem-calc(12);
        margin-left: .25rem;

        &::before {
          content: '✕';
        }
      }

      small {
        color: $dark-gray;
        font-size: rem-calc(12);
        margin-left: .5rem;
      }

      &.more a {
        border-bottom: 1px dashed;
        color: $black;
        cursor: pointer;
      }
    }

    @include breakpoint(small only) {
      margin: 0;
      padding: 0 10px;

      dd {
        padding: 15px 10px 15px 5px;
        box-sizing: border-box;
        border-bottom: 1px solid $medium-gray;
        line-height: initial;
        position: relative;
        font-size: rem-calc(15);
        font-weight: $global-weight-bold;

        &:last-child {
          border-bottom: none;
        }

        a {
          display: block;
          color: $black !important;
          font-size: rem-calc(15);
          font-weight: $global-weight-normal;

          &.gl {
            position: absolute;
            width: 100%;
            text-align: right;
            right: 0;
            top: 10px;
            font-weight: $global-weight-normal;
          }
        }

        span {
          display: none;
        }
      }
    }
  }
}

;@include ---kha1f6wtc2.load-css("sass-embedded-legacy-load-done:3750");